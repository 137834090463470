@page {
  size: a4 landscape;
}

$my-blue: #36589C !default;
$my-yellow: #F4DE4C !default;
$my-red: #B43924 !default;
$my-green: #50A94A !default;
$my-orange: #DF8412 !default;
$black: #212529 !default;

$body-bg:                #fefefe; 
$primary:                $my-blue;
$dark:                   #36589C;
$secondary:              $my-yellow;

$transition-collapse: height .05s ease;

.sidebar .nav-item .nav-link,
.sidebar .nav-item .nav-link:focus,
.sidebar .nav-item .nav-link:hover {

    border: 0px;
    color: #fefefe;
}


@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";

/*
$theme-colors: (
  primary: $purple
);
*/

$input-border-color: $gray-600;
$form-select-border-color: $gray-600;

@import "../node_modules/bootstrap/scss/bootstrap";


html {
    scroll-behavior: auto;
}

.make-white {
   margin: 0px 10px; // 2px; // 2px 2px;
   height: 1.1em;
   width: 1.1em;
   border-radius: 100%;
   background: white;
   text-align: center;
   line-height: 1.05em;
}

.wide-icon {
    overflow: visible;
    margin-left: -2px;
    margin-right: -2px;
}

.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
    overflow: hidden;
}

.rounded-image {
    height: 100px;
    border: 0px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.big-icon {
    font-size: 32px;
}

/* Footer */

.page-footer {
  //bottom: 0px;
  padding-top: 40px;
  padding-bottom: 40px;
  width: 100%;
  background: #37474f;
  color: #fefefe;
  padding-left: 15%;
  padding-right: 15%;
}

.page-footer a {
    color: #ffffff !important;
}

.footer-contact-list {
    line-height: 2.5em;
}

/* General */

.app {
    padding-top: 25px;
    padding-bottom: 25px;
    background-color: $body-bg;
    margin-left: 5%;
    margin-right: 5%;
}

section {
    padding-top: 25px;
    padding-bottom: 25px;
    background-color: $body-bg;
    margin-left: 5%;
    margin-right: 5%;
}

.subtitle {
    font-size: 2.5rem;
    position: relative;
    top: 8px;
    font-weight: 550;
    color: #36589C;
}

.content {
    margin-top: 10px;
}

/* Tables */

.table {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing:0 20px;
}

.th {
    width: 50px;
}

/* Navbar */

.navbar-custom {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    border: 0px;
}

.my-location-icon {
    margin-top: 7px;
}

.my-location-icon-geo {
    margin-top: 7px;
}

.my-location-icon-x {
    margin-top: 9px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.my-location-change {
    margin-top: 4px;
}

.my-location {
    margin-top: 12px;
}

@include media-breakpoint-up(lg) {
    .my-location-icon {
        margin-top: 37px;
    }

    .my-location-change {
        margin-top: 35px;
        margin-bottom: 1px;
        padding-bottom: 0px;
        padding-top: 0px;
    }

    .my-location-icon-geo {
        margin-top: 37px;
    }

    .my-location-icon-x {
        margin-top: 37px;
    }

    .my-location {
        margin-top: 42px;
    }

    .navbar-custom .nav-item {
        padding-right: 5px;
        padding-left: 5px;
        padding-top: 2px;
        margin-top: 33px;
    }

    .navbar-custom .nav-item.active {
        border-radius: 12px 12px 0 0;
        background-color: white;
    }

    .navbar-custom .nav-item.active .nav-link {
        color: $white; 
        color: $my-blue;
    }

    .navbar-custom .nav-item.active .dropdown-item {
        color: $white; 
        color: $my-blue;
    }
}

.navbar-custom a.dropdown-toggle, a:focus.dropdown-toggle, a:hover.dropdown-toggle {
    color: #fefefe;
    text-decoration: none;
}

.navbar-custom .navbar-nav .nav-link {
    color: #fefefe;
}

/* Calendar */

.month .mon {
    min-width: 30px; 
    text-align: center;
}
.month .tue {
    min-width: 30px; 
    text-align: center;
}
.month .wed {
    min-width: 30px; 
    text-align: center;
}
.month .thu {
    min-width: 30px; 
    text-align: center;
}
.month .fri {
    min-width: 30px; 
    text-align: center;
}
.month .sat {
    min-width: 30px; 
    text-align: center;
}
.month .sun {
    min-width: 30px; 
    text-align: center;
}
.month .noday {
    color: gray;
    min-width: 30px; 
    text-align: center;
}

/* Global tool */

.with-separator:not(:empty):before {
   content: " | ";
}

.with-space:not(:empty):after {
   content: " ";
}

.visually-disabled {
    opacity: 0.5;
}

/* Typeahead */

span.twitter-typeahead .tt-menu,
span.twitter-typeahead .tt-dropdown-menu {
  cursor: pointer;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
}

span.twitter-typeahead .tt-suggestion {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.42857143;
  color: #333333;
  white-space: nowrap;
}

span.twitter-typeahead .tt-suggestion.tt-cursor,
span.twitter-typeahead .tt-suggestion:hover,
span.twitter-typeahead .tt-suggestion:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  background-color: #337ab7;
}

.input-group.input-group-lg span.twitter-typeahead .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

.input-group.input-group-sm span.twitter-typeahead .form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

span.twitter-typeahead {
  width: 100%;
}

.input-group span.twitter-typeahead {
  display: block !important;
  height: 34px;
}

.input-group span.twitter-typeahead .tt-menu,
.input-group span.twitter-typeahead .tt-dropdown-menu {
  top: 32px !important;
}

.input-group span.twitter-typeahead:not(:first-child):not(:last-child) .form-control {
  border-radius: 0;
}

.input-group span.twitter-typeahead:first-child .form-control {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group span.twitter-typeahead:last-child .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.input-group.input-group-sm span.twitter-typeahead {
  height: 30px;
}
.input-group.input-group-sm span.twitter-typeahead .tt-menu,
.input-group.input-group-sm span.twitter-typeahead .tt-dropdown-menu {
  top: 30px !important;
}
.input-group.input-group-lg span.twitter-typeahead {
  height: 46px;
}
.input-group.input-group-lg span.twitter-typeahead .tt-menu,
.input-group.input-group-lg span.twitter-typeahead .tt-dropdown-menu {
  top: 46px !important;
}

.my-mt-25 {
    margin-top: 25px;
}
.my-mt-40 {
    margin-top: 40px;
}
.my-mt-20 {
    margin-top: 20px;
}

.tooltip .tooltip-inner {
    text-align: left !important;
}

input[type="radio"] {
    border-color: black;
}

.circle-icon {
    background: #ffc107 !important;
    height: 26px;
    width: 26px;
    border-radius: 50%;
}

/* Map */

.mapholder{
    height: 500px;
    width: 100%;
}

#map {
    height: 100%;
  }

span[color=text-white] a {
    color: white;
}

.badge-outline-primary {
    color: $dark;
    //background-color: transparent;
    //background-image: none;
    border-color: $dark;
    border: 1px solid;
}

.no-bullets {
    list-style-type: none;
}
